<script>
  import { onMount } from "svelte";

  import { svgKeyId } from "../arabicChars.js";

  import keyboardImage from "./svg/kb_arabic_pc.svg";

  let showError = false;

  export let lesson;

  let keyboard;
  onMount(() => {
    keyboard = document.querySelector("#keyViewer");
  });

  // Update to highlight next key
  $: {
    if (keyboard) {
      keyboard
        .querySelectorAll("rect.next")
        .forEach(rect => rect.classList.remove("next"));

      try {
        let key = keyboard.querySelector(`#${svgKeyId(lesson.nextKey)} rect`);
        if (key) key.classList.add("next");
      } catch (e) {}
    }
  }

  let keydown = event => {
    let key = keyboard.querySelector(`#${svgKeyId(event.key)} rect`);
    if (key) {
      if (event.key === lesson.nextKey) {
        key.classList.add("correct");
      } else {
        key.classList.add("incorrect");
      }
    }

    // key.querySelector("rect").setAttribute("fill", "red");
  };
  let keyup = event => {
    let key = keyboard.querySelector(`#${svgKeyId(event.key)} rect`);
    if (key) {
      key.classList.remove("correct");
      key.classList.remove("incorrect");
    }
  };

  let applyStyle = () => {
    keyboard
      .querySelector("#space")
      .setAttribute("fill", spaceKey ? "lime" : "black");

    if (!jKey && fKey) {
      keyboard.querySelector("#outer").setAttribute("fill", "red");
    } else if (jKey && !fKey) {
      keyboard.querySelector("#outer").setAttribute("fill", "dodgerblue");
    } else if (jKey && fKey) {
      keyboard.querySelector("#outer").setAttribute("fill", "magenta");
    } else {
      keyboard.querySelector("#outer").setAttribute("fill", "none");
    }
  };
</script>

<style>
  .hide {
    display: none !important;
  }

  .wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  #keyViewer {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error {
    display: inline;
    padding: 0.5em;
    background: red;
  }

  /* SVG Styles */
  :global(#keyViewer .next) {
    fill: royalblue !important;
  }
  :global(#keyViewer #Backspace .next) {
    fill: red !important;
  }

  :global(#keyViewer .correct) {
    fill: limegreen !important;
  }

  :global(#keyViewer .incorrect) {
    fill: red !important;
  }
</style>

<svelte:window on:keydown={keydown} on:keyup={keyup} />
<div class="wrapper">
  <div id="keyViewer" class:hide={showError}>
    {@html keyboardImage}
  </div>

  <h1 class="error title" class:hide={!showError}>
    Error: Could not load keyboard!
  </h1>
</div>
