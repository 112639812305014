export const all = [
  "ء",
  "آ",
  "أ",
  "ؤ",
  "إ",
  "ئ",
  "ا",
  "ب",
  "ة",
  "ت",
  "ث",
  "ث",
  "ج",
  "ح",
  "خ",
  "د",
  "ذ",
  "ر",
  "ز",
  "س",
  "ش",
  "ص",
  "ض",
  "ط",
  "ظ",
  "ع",
  "غ",
  "ـ",
  "ف",
  "ق",
  "ك",
  "ل",
  "م",
  "ن",
  "ه",
  "و",
  "ى",
  "ي",
  "ً",
  "ٌ",
  "ٍ",
  "َ",
  "ُ",
  "ِ",
  "ّ",
  "ْ",
  "ٓ",
  "ٓ",
  "ٰ",
  "ٰ",
  "ٱ",
  "ٹ",
  "پ",
  "چ",
  "ڈ",
  "ڑ",
  "ژ",
  "ڤ",
  "گ",
  "ں",
  "ی",
  "ے"
];
export const tashkil = ["ً", "ٌ", "ٍ", "َ", "ُ", "ِ", "ّ", "ّ", "ْ", "ٓ", "ٰ"];
export const joinsToPrev = [
  "آ",
  "أ",
  "ؤ",
  "إ",
  "ئ",
  "ا",
  "ب",
  "ة",
  "ت",
  "ت",
  "ث",
  "ج",
  "ح",
  "خ",
  "د",
  "ذ",
  "ر",
  "ز",
  "س",
  "ش",
  "ص",
  "ض",
  "ط",
  "ظ",
  "ع",
  "غ",
  "ـ",
  "ف",
  "ق",
  "ك",
  "ل",
  "م",
  "ن",
  "ه",
  "و",
  "ى",
  "ي",
  "ٱ",
  "ٹ",
  "پ",
  "چ",
  "ڈ",
  "ڑ",
  "ژ",
  "ڤ",
  "گ",
  "ں",
  "ے"
];
export const joinsToNext = [
  "ئ",
  "ب",
  "ة",
  "ت",
  "ث",
  "ث",
  "ج",
  "ح",
  "خ",
  "س",
  "ش",
  "ص",
  "ض",
  "ط",
  "ظ",
  "ع",
  "غ",
  "ـ",
  "ف",
  "ق",
  "ك",
  "ل",
  "م",
  "ن",
  "ه",
  "ى",
  "ي",
  "ٹ",
  "پ",
  "چ",
  "ڤ",
  "گ",
  "ں",
  "ی"
];

const zwj = "\u200d";

//  // To join arabic chars when a word is split into different spans, use &zwj. Unfortuantely we must manually compute this since svelte insists in inserting spaces between elements

export const doJoinChar = (prevString, nextString) => {
  let prevChar = prevString.length - 1,
    nextChar = 0;

  while (true) {
    if (
      prevString[prevChar] === undefined ||
      nextString[nextChar] === undefined
    ) {
      // Ended string and found to non tashkeel, therefore no joining needed!
      return "";
    }

    if (tashkil.includes(prevString[prevChar])) {
      prevChar--;
      continue;
    }
    if (tashkil.includes(nextString[nextChar])) {
      nextChar++;
      continue;
    }

    if (
      joinsToNext.includes(prevString[prevChar]) &&
      joinsToPrev.includes(nextString[nextChar])
    ) {
      return zwj;
    } else {
      return "";
    }
  }
};

export const svgKeyId = key => key === " " ? "space" : key;

window.p = { all, joinsToPrev, joinsToNext, doJoinChar };

export default { all, joinsToPrev, joinsToNext, doJoinChar };
