<script>
  import { doJoinChar as joinChar } from "../arabicChars.js";

  export let lesson;
</script>

<style>
  .wrapper {
    padding: 3rem;
    text-align: center;
    font-size: 0;
  }
  .text {
    font-size: 4rem;
    font-style: normal;
  }
  .textNext {
    color: royalblue;
    text-decoration: underline;
  }
  .textTyped {
    color: red;
    text-decoration: underline;
  }
  .textCorrect {
    color: limegreen;
  }
</style>

<div class="wrapper" id="textWrapper">
  <i class="text textCorrect">
    {lesson.correctText + joinChar(lesson.correctText, lesson.incorrectText + lesson.nextText + lesson.untypedText)}
  </i>
  <i class="text textTyped">
    {joinChar(lesson.correctText, lesson.incorrectText + lesson.nextText + lesson.untypedText) + lesson.incorrectText + joinChar(lesson.correctText + lesson.incorrectText, lesson.nextText + lesson.untypedText)}
  </i>
  <i class="text textNext">
    {joinChar(lesson.correctText + lesson.incorrectText, lesson.nextText + lesson.untypedText) + lesson.nextText + joinChar(lesson.correctText + lesson.incorrectText + lesson.nextText, lesson.untypedText)}
  </i>
  <i class="text textUntyped">
    {joinChar(lesson.correctText + lesson.incorrectText + lesson.nextText, lesson.untypedText) + lesson.untypedText}
  </i>
</div>
