export default class Lesson {
  constructor(text) {
    this.text = text;
    this.typedText = "";
    this.correctPos = 0;
  }

  handleKey(event) {
    if (
      event.code === "Backspace" &&
      this.typedText.length > 0 &&
      this.typedText.length > this.correctPos
    ) {
      this.typedText = this.typedText.slice(0, -1);
    } else if (
      event.key.length === 1 &&
      this.typedText.length < this.text.length
    ) {
      // i.e is text key

      // Note to self: event.key will change with languages (it is the character being typed). Use event.keyCode to get the physical key name (KeyA, Slash, ControlLeft etc.)
      if (event.key === this.text[this.typedText.length]) this.correctPos += 1;

      this.typedText += event.key;
    }
  }

  get nextKey() {
    return this.incorrectText ? "Backspace" : this.text[this.correctPos] || "";
  }
  get prevKey() {
    return this.text[this.correctPos - 1] || "";
  }

  get cursorPos() {
    return this.typedText.length
  }

  get correctText() {
    return (this.text.slice(0, this.correctPos) || "").replace(" ", "\xa0")
  }

  get incorrectText() {
    return (this.typedText.slice(this.correctPos, this.cursorPos) || "").replace(" ", "\xa0");
  }

  get nextText() {
    return (this.text[this.cursorPos] || "").replace(" ", "\xa0");
  }

  get untypedText() {
    return (this.text.slice(this.cursorPos + 1) || "").replace(" ", "\xa0")

  }
}
