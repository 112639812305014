<script>
  import Keyboard from "./keyboard/Keyboard.svelte";
  import TextViewer from "./viewer/TextViewer.svelte";

  import Lesson from "./controller/lesson.js";

  let sampleText = "هَذه ملعقتي";

  let currentLesson = new Lesson(sampleText);
  let updateHack = false;
</script>

<style>
  :global(body) {
    @import url("https://fonts.googleapis.com/css?family=Katibeh&display=swap");

    margin: 0;
    padding: 0;

    font-family: "Katibeh", sans-serif;

    box-sizing: border-box;
  }

  :global(.title, h1, h2, h3, h4, h5, h6) {
    font-family: sans-serif;
  }

  section {
    height: 50%;
  }
</style>

<svelte:window
  on:keydown={event => {
    currentLesson.handleKey(event);
    currentLesson = currentLesson;
  }} />

<section>
  <TextViewer lesson={currentLesson} />
</section>
<section>
  <Keyboard lesson={currentLesson} />
</section>
